body {
  background-color: #f5f5f5;
  font-family: 'Arial', sans-serif;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-center{
  max-width: 30rem;
  margin: 20px;
  display:flex;
  flex-direction: row;
}

.card-header{
  display: flex;
  align-items:center;
}

.card-body{
  display:flex;
  align-items:center;
  flex-direction: row;
  justify-content: space-between;
}

.card-title{
  margin:10px;
  text-align: left;
  margin-left:5rem;
}

.subscription-tiers{
  display:flex;
  flex-direction:column;
  align-items: center;
  justify-content: space-around;
}

.disconnect-button, .connect-button{
  display:flex;
  justify-content: end;
  padding-bottom:1rem;
}

.main{
  max-width: 40rem;
  display: flex;
  flex-direction: column;
  min-height:50rem;
}

.card{
  min-width:30rem;
  max-width:40rem;
}

.alert{
  text-align: center;
}


.logo img{
  max-width: 25rem;
}

.footer img{
  max-width:10rem;
}